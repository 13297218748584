export const useFavicon = () => {
    const { theme } = useAppConfig();

    const faviconLink = computed(() => {
        return [
            { rel: 'shortcut icon', type: 'image/x-icon', href: `/favicons/${theme}/favicon.ico` },
            { rel: 'icon', type: 'image/png', sizes: '16x16', href: `/favicons/${theme}/favicon-16x16.png` },
            { rel: 'icon', type: 'image/png', sizes: '32x32', href: `/favicons/${theme}/favicon-32x32.png` },

            { rel: 'apple-touch-icon', sizes: '180x180', href: `/favicons/${theme}/apple-touch-icon.png` },
            { rel: 'mask-icon', href: `/favicons/${theme}/safari-pinned-tab.svg`, color: faviconThemeColor },

            { rel: 'manifest', href: `/favicons/${theme}/site.webmanifest`, crossorigin: 'use-credentials' },
        ];
    });

    const faviconMeta = computed(() => {
        return [
            { name: 'msapplication-TileColor', content: faviconThemeColor },
            { name: 'msapplication-config', content: `/favicons/${theme}/browserconfig.xml` },
            { name: 'theme-color', content: faviconThemeColor }
        ];
    });

    const faviconThemeColor = computed(() => {
        switch (theme) {
        case 'theme--doen':
            return '#007cbe';
        case 'theme--participaties':
            return '#223262';
        case 'theme--bglf':
            return '#ffffff';
        default:
            return '#007cbe';
        }
    });

    useHead({
        meta: faviconMeta,
        link: faviconLink
    });
};
